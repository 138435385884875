import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthContextProps {
  isAuthenticated: boolean | null;
  orgNumber: string | null;
  locationId: number | null;
  prayerTimeTableId: number | null;
  uid: string | null;
  mosqueName: string | null;
  mosqueLogo: string | null;
  isSuperAdmin: boolean | null;
  selectedOrgNumber: string | null;
  selectedOrgName: string | null;
  superAdminMode: boolean;
  setOrgNumber: (orgNumber: string) => void;
  setLocationId: (locationId: number) => void;
  setPrayerTimeTableId: (prayerTimeTableId: number) => void;
  setUid: (uid: string) => void;
  setMosqueName: (mosqueName: string) => void;
  setMosqueLogo: (mosqueLogo: string) => void;
  setIsSuperAdmin: (isSuperAdmin: boolean) => void;
  setSelectedOrgNumber: (orgNumber: string) => void;
  setSelectedOrgName: (orgName: string) => void;
  setSuperAdminMode: (mode: boolean) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [orgNumber, setOrgNumber] = useState<string | null>(null);
  const [locationId, setLocationId] = useState<number | null>(null);
  const [prayerTimeTableId, setPrayerTimeTableId] = useState<number | null>(
    null,
  );
  const [uid, setUid] = useState<string | null>(null);
  const [mosqueName, setMosqueName] = useState<string | null>(null);
  const [mosqueLogo, setMosqueLogo] = useState<string | null>(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(null);
  const [selectedOrgNumber, setSelectedOrgNumber] = useState<string | null>(
    null,
  );
  const [selectedOrgName, setSelectedOrgName] = useState<string | null>(null);
  const [superAdminMode, setSuperAdminMode] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const publicRoutes = ["/ny-moske"];

  const isPublicRoute = publicRoutes.includes(location.pathname);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token && !isPublicRoute) {
      console.log("No token found, redirecting to login");
      setIsAuthenticated(false);
      navigate("/innlogging");
    } else {
      setIsAuthenticated(true);
      const storedOrgNumber = sessionStorage.getItem("orgNumber");
      const storedLocationId = sessionStorage.getItem("locationId");
      const storedPrayerTimeTableId =
        sessionStorage.getItem("prayerTimeTableId");
      const storedUid = sessionStorage.getItem("uid");
      const storedMosqueName = sessionStorage.getItem("mosqueName");
      const storedMosqueLogo = sessionStorage.getItem("mosqueLogo");
      const storeIsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
      const storedSelectedOrgNumber =
        sessionStorage.getItem("selectedOrgNumber");
      const storedSuperAdminMode = sessionStorage.getItem("isSuperAdminMode");
      const storedSelectedOrgName = sessionStorage.getItem("selectedOrgName");

      if (storedOrgNumber) setOrgNumber(storedOrgNumber);
      if (storedLocationId) setLocationId(parseInt(storedLocationId));
      if (storedPrayerTimeTableId)
        setPrayerTimeTableId(parseInt(storedPrayerTimeTableId));
      if (storedUid) setUid(storedUid);
      if (storedMosqueName) setMosqueName(storedMosqueName);
      if (storedMosqueLogo) setMosqueLogo(storedMosqueLogo);
      if (storeIsSuperAdmin) setIsSuperAdmin(JSON.parse(storeIsSuperAdmin));
      if (storedSelectedOrgNumber)
        setSelectedOrgNumber(storedSelectedOrgNumber);
      if (storedSelectedOrgName) setSelectedOrgName(storedSelectedOrgName);
      if (storedSuperAdminMode)
        setSuperAdminMode(JSON.parse(storedSuperAdminMode));
    }
  }, [isPublicRoute]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        orgNumber,
        selectedOrgName,
        locationId,
        prayerTimeTableId,
        uid,
        mosqueName,
        mosqueLogo,
        isSuperAdmin,
        selectedOrgNumber,
        superAdminMode,
        setOrgNumber,
        setSelectedOrgName,
        setLocationId,
        setPrayerTimeTableId,
        setUid,
        setMosqueName,
        setMosqueLogo,
        setIsSuperAdmin,
        setSelectedOrgNumber,
        setSuperAdminMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};

export default AuthProvider;
