import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Pagination } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import styles from "./DuaPage.module.scss";

interface Dua {
  id: number;
  kategori: string;
  tittel: string;
  arabisk: string;
  arabisk1: string;
  oversettelse: string;
  transliteration: string;
  referanse: string;
  tags: string;
}

const DuaPage: React.FC = () => {
  const { execute } = useFetch();
  const [duas, setDuas] = useState<Dua[]>([]);
  const [filteredDuas, setFilteredDuas] = useState<Dua[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedDua, setSelectedDua] = useState<Dua | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    const fetchDuas = async () => {
      const duaResult = (await execute("/api/v1/duas", "GET")) as Dua[];
      if (duaResult) setDuas(duaResult);
    };
    fetchDuas();
  }, []);

  const uniqueCategories = Array.from(new Set(duas.map((dua) => dua.kategori)));

  useEffect(() => {
    const filtered = duas.filter((dua) => {
      const matchesSearch = dua.tittel
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesCategory = category === "" || dua.kategori === category;
      return matchesSearch && matchesCategory;
    });
    setFilteredDuas(filtered);
    setCurrentPage(1);
  }, [searchTerm, category, duas]);

  const handleEditClick = (dua: Dua) => {
    setSelectedDua(dua);
  };

  const handleSaveChanges = async () => {
    if (selectedDua) {
      await execute(`/api/v1/duas/${selectedDua.id}`, "PATCH", selectedDua);
      setSelectedDua(null);
      alert("Dua updated!");
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (selectedDua) {
      const { name, value } = e.target;
      setSelectedDua((prev) => (prev ? { ...prev, [name]: value } : prev));
    }
  };

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDuas.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-4">
      <h1>Oppdater Dua</h1>
      <hr />
      {/* Search bar */}
      <Form.Control
        type="text"
        placeholder="Søk etter tittel..."
        className="mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Category filter */}
      <Form.Select
        className="mb-4"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      >
        <option value="">Velg kategori</option>
        {uniqueCategories.map((cat) => (
          <option key={cat} value={cat}>
            {cat}
          </option>
        ))}
      </Form.Select>

      {/* Dua List */}
      <div className="dua-list">
        {currentItems.map((dua) => (
          <Card key={dua.id} className="mb-3">
            <Card.Header className={styles.cardHeader}>
              <strong>{dua.tittel}</strong> {dua.kategori}
            </Card.Header>
            <Card.Body>
              <p>{dua.oversettelse}</p>
              <Button variant="primary" onClick={() => handleEditClick(dua)}>
                Rediger
              </Button>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Pagination */}
      <Pagination>
        {Array.from(
          { length: Math.ceil(filteredDuas.length / itemsPerPage) },
          (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ),
        )}
      </Pagination>

      {/* Edit Modal */}
      <Modal show={!!selectedDua} onHide={() => setSelectedDua(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rediger Dua</Modal.Title>
        </Modal.Header>
        {selectedDua && (
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Tittel</Form.Label>
                <Form.Control
                  type="text"
                  name="tittel"
                  value={selectedDua.tittel}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Arabisk</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="arabisk"
                  value={selectedDua.arabisk}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Oversettelse</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="oversettelse"
                  value={selectedDua.oversettelse}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Transliteration</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="transliteration"
                  value={selectedDua.transliteration}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Referanse</Form.Label>
                <Form.Control
                  type="text"
                  name="referanse"
                  value={selectedDua.referanse}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tags</Form.Label>
                <Form.Control
                  type="text"
                  name="tags"
                  value={selectedDua.tags}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedDua(null)}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Lagre Endringer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DuaPage;
