import { AsrMethod, IconState } from "enums/AdminPageEnums";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, FormCheck } from "react-bootstrap";
import Header from "stories/Header/Header";
import { AdminFormValues, MosqueInfo } from "types/AdminPageTypes";
import * as Yup from "yup";
import { ReactComponent as DefaultLogo } from "../../assets/default-logo.svg";
import { useAuth } from "../../AuthProvider";
import useFetch from "../../hooks/useFetch";
import styles from "./AdminPage.module.scss";
import AdminPageSkeleton from "./AdminPageSkeleton/AdminPageSkeleton";
import TextField from "./AdminTextField.tsx";
import icons from "./IconComponents";

const validationSchema = Yup.object({
  orgNr: Yup.string(),
  orgName: Yup.string(),
  name: Yup.string(),
  streetAddress: Yup.string(),
  contactName: Yup.string(),
  contactEmail: Yup.string(),
  contactPhone: Yup.string(),
  postalCode: Yup.string(),
  city: Yup.string(),
  prayerTimeTable: Yup.number(),
});

const AdminPage: React.FC = () => {
  const { execute, data, error, isLoading } = useFetch<AdminFormValues>();
  const { execute: fetchMosqueInfo, data: mosqueInfoData } =
    useFetch<MosqueInfo>();
  const [mosqueInfo, setMosqueInfo] = useState<MosqueInfo | null>(null);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [initialFormValues, setInitialFormValues] =
    useState<AdminFormValues | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [iconStates, setIconStates] = useState<{ [key: string]: boolean }>({});
  const {
    setLocationId,
    setPrayerTimeTableId,
    selectedOrgNumber,
    setMosqueName,
    setMosqueLogo,
  } = useAuth();

  const [prayerTimeTables, setPrayerTimeTables] = useState<
    Array<{ id: number; name: string }>
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      setMosqueInfo(null);
      setInitialFormValues(null);
      setLogoUrl(null);
      setIconStates({});

      const mosqueResult = await execute(
        `/api/v1/mosques/${selectedOrgNumber}`,
        "GET",
      );
      const mosqueInfoResult = await fetchMosqueInfo(
        `/api/v1/mosqueInfo/org/${selectedOrgNumber}`,
        "GET",
      );

      if (mosqueResult) {
        const logo = mosqueResult.logo || null;
        setLogoUrl(logo);

        if (logo) {
          sessionStorage.setItem("mosqueLogo", logo);
          setMosqueLogo(logo);
        }

        setInitialFormValues({
          ...mosqueResult,
          menCapacity: mosqueInfoResult?.capacityMen ?? 100,
          womenCapacity: mosqueInfoResult?.capacityWomen ?? 100,
          asrMethod: mosqueResult.asrMethod || "NONE",
          prayerTimeTable: mosqueResult.prayerTimeTable || 0,
        });

        setMosqueName(mosqueResult.name);
        sessionStorage.setItem("mosqueName", mosqueResult.name);

        if (mosqueResult.locationId) {
          setLocationId(mosqueResult.locationId);

          const locationData = await execute(
            `/api/v1/locations/${mosqueResult.locationId}`,
            "GET",
          );

          if (locationData && locationData.prayerTimesTables) {
            setPrayerTimeTables(locationData.prayerTimesTables);
          }

          if (mosqueResult.prayerTimeTable) {
            setPrayerTimeTableId(mosqueResult.prayerTimeTable);
            sessionStorage.setItem(
              "prayerTimeTableId",
              mosqueResult.prayerTimeTable.toString(),
            );
          }
        }
      }

      if (mosqueInfoResult) {
        setMosqueInfo(mosqueInfoResult);
        setIconStates({
          ablutionMen: mosqueInfoResult.ablutionMen,
          ablutionWomen: mosqueInfoResult.ablutionWomen,
          hallHire: mosqueInfoResult.hallHire,
          iftar: mosqueInfoResult.iftar,
          nikah: mosqueInfoResult.nikah,
          janazah: mosqueInfoResult.janazah,
          advice: mosqueInfoResult.advice,
          newMuslim: mosqueInfoResult.newMuslim,
          jummah: mosqueInfoResult.jummah,
          taraweeh: mosqueInfoResult.taraweeh,
          eid: mosqueInfoResult.eid,
          parking: mosqueInfoResult.parking,
          madrassahAdult: mosqueInfoResult.madrassahAdult,
          madrassahChildren: mosqueInfoResult.madrassahChildren,
          kitchen: mosqueInfoResult.kitchen,
          toiletMen: mosqueInfoResult.toiletMen,
          toiletWomen: mosqueInfoResult.toiletWomen,
          darsWomen: mosqueInfoResult.darsWomen,
        });
      } else {
        setIconStates({
          ablutionMen: true,
          ablutionWomen: true,
          hallHire: true,
          iftar: true,
          nikah: true,
          janazah: true,
          advice: true,
          newMuslim: true,
          jummah: true,
          taraweeh: true,
          eid: true,
          parking: true,
          madrassahAdult: true,
          madrassahChildren: true,
          kitchen: true,
          toiletMen: true,
          toiletWomen: true,
          darsWomen: true,
        });
      }
    };

    fetchData();
  }, [selectedOrgNumber]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = () => {
        setLogoUrl(reader.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleLogoUpload = async (): Promise<string | null> => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const result = await execute(
          `/api/v1/files/upload/LOGO/${selectedOrgNumber}`,
          "POST",
          formData,
        );

        if (result && result.filePath) {
          return result.filePath;
        } else {
          setGeneralError("En feil oppstod under opplasting av logo.");
          return null;
        }
      } catch (error) {
        setGeneralError("En feil oppstod under opplasting av logo.");
        return null;
      }
    }
    return null;
  };

  const iconStateMapping: { [key: string]: IconState } = {
    "Barne Madrassa": IconState.MadrassahChildren,
    Rådgivning: IconState.Advice,
    "Kurs for kvinner": IconState.DarsWomen,
    Eid: IconState.Eid,
    "Leie hall": IconState.HallHire,
    Janazah: IconState.Janazah,
    "Jummah bønn": IconState.Jummah,
    Kjøkken: IconState.Kitchen,
    "Nikkah tjenester": IconState.Nikah,
    Parkering: IconState.Parking,
    "Ramadan iftar": IconState.Iftar,
    "Støtte ny-muslimer": IconState.NewMuslim,
    Taraweeh: IconState.Taraweeh,
    "Toaletter Menn": IconState.ToiletMen,
    "Toaletter Kvinner": IconState.ToiletWomen,
    "Voksen Madrassa": IconState.MadrassahAdult,
    "Wudu Menn": IconState.AblutionMen,
    "Wudu Kvinner": IconState.AblutionWomen,
  };

  const handleSubmit = async (values: AdminFormValues) => {
    const payload = {
      orgNr: values.orgNr,
      name: values.name,
      orgName: values.orgName,
      streetAddress: values.streetAddress,
      postalCode: values.postalCode,
      city: values.city,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      contactEmail: values.contactEmail,
      asrMethod: values.asrMethod,
      prayerTimeTable: values.prayerTimeTable,
    };

    const mosqueInfoPayload = {
      capacityMen: values.menCapacity,
      capacityWomen: values.womenCapacity,
      ablutionMen: iconStates.ablutionMen,
      ablutionWomen: iconStates.ablutionWomen,
      hallHire: iconStates.hallHire,
      iftar: iconStates.iftar,
      nikah: iconStates.nikah,
      janazah: iconStates.janazah,
      advice: iconStates.advice,
      newMuslim: iconStates.newMuslim,
      jummah: iconStates.jummah,
      taraweeh: iconStates.taraweeh,
      eid: iconStates.eid,
      parking: iconStates.parking,
      madrassahAdult: iconStates.madrassahAdult,
      madrassahChildren: iconStates.madrassahChildren,
      kitchen: iconStates.kitchen,
      toiletMen: iconStates.toiletMen,
      toiletWomen: iconStates.toiletWomen,
      mosqueOrgNr: selectedOrgNumber,
      darsWomen: iconStates.darsWomen,
    };

    try {
      const result = await execute(
        `/api/v1/mosques/${selectedOrgNumber}`,
        "PATCH",
        payload,
      );

      if (mosqueInfo?.id) {
        await execute(
          `/api/v1/mosqueInfo/${mosqueInfo.id}`,
          "PATCH",
          mosqueInfoPayload,
        );
      } else {
        await execute(`/api/v1/mosqueInfo`, "POST", mosqueInfoPayload);
      }

      if (result) {
        let newLogoUrl = logoUrl;
        if (selectedFile) {
          newLogoUrl = await handleLogoUpload();
        }

        setInitialFormValues({
          ...values,
          logo: newLogoUrl || values.logo,
        });

        setMosqueName(values.name);
        if (newLogoUrl) {
          setMosqueLogo(newLogoUrl);
          sessionStorage.setItem("mosqueLogo", newLogoUrl);
        }

        sessionStorage.setItem("mosqueName", values.name);
      } else {
        setGeneralError("Failed to update mosque information.");
      }
    } catch (error) {
      setGeneralError("An error occurred while updating mosque information.");
    }
  };

  const handleToggle = (key: string) => {
    setIconStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  if (isLoading) {
    return <AdminPageSkeleton />;
  }

  if (error || generalError) {
    return (
      <div>
        <Header title="Moské Informasjon" />
        <br />
        <div className="alert alert-danger">
          {error?.text ||
            generalError ||
            "Ingen data tilgjengelig for valgt moske."}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header title="Moské Informasjon" />
      <div className="mt-5">
        {initialFormValues && (
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {() => (
              <Form>
                {/* Organisasjon Detaljer */}
                <div className="card mb-4">
                  <div className="card-header fw-bold">
                    Organisasjonsdetaljer
                  </div>
                  <div className="card-body">
                    <TextField
                      name="orgNr"
                      label="Organisasjonsnummer"
                      disabled
                    />
                    <TextField
                      name="orgName"
                      label="Organisasjonsnavn"
                      disabled
                    />
                    <div className="form-group">
                      <label htmlFor="asrMethod">Asr Metode:</label>
                      <Field
                        as="select"
                        name="asrMethod"
                        className="form-control mb-3"
                        placeholder="Velg Asr Metode"
                      >
                        <option value={AsrMethod.NONE}>Ingen</option>
                        <option value={AsrMethod.MIXED}>Mixed</option>
                        <option value={AsrMethod.ONE_X}>1x</option>
                        <option value={AsrMethod.TWO_X}>2x</option>
                      </Field>
                      <ErrorMessage
                        name="asrMethod"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="prayerTimeTable">Bønnetabell:</label>
                      <Field
                        as="select"
                        name="prayerTimeTable"
                        className="form-control mb-3"
                        placeholder="Velg Bønnetabell"
                      >
                        {prayerTimeTables.map((table) => (
                          <option key={table.id} value={table.id}>
                            {table.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="prayerTimeTable"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>

                {/* Masjid Detaljer */}
                <div className="card mb-4">
                  <div className="card-header fw-bold">Masjid Detaljer</div>
                  <div className="m-4 d-flex justify-content-start">
                    <div className={styles.logoContainer}>
                      {logoUrl ? (
                        <img
                          src={logoUrl}
                          alt="Masjid Logo"
                          className={styles.logoImg}
                          onError={(e) => {
                            e.currentTarget.style.display = "none";
                            e.currentTarget.parentElement!.textContent =
                              "Masjid Logo";
                          }}
                        />
                      ) : (
                        <DefaultLogo className={styles.defaultLogo} />
                      )}
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="logoUpload">Last opp logo:</label>
                      <input
                        type="file"
                        id="logoUpload"
                        className="form-control mb-3"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </div>
                    <TextField name="name" label="Masjid Navn" />
                    <TextField name="streetAddress" label="Masjid Adresse" />
                    <TextField name="postalCode" label="Postnr" />
                    <TextField name="city" label="Sted" />
                  </div>
                </div>

                {/* Kontaktperson */}
                <div className="card mb-4">
                  <div className="card-header fw-bold">Kontaktperson:</div>
                  <div className="card-body">
                    <TextField name="contactName" label="Navn" />
                    <TextField
                      name="contactEmail"
                      label="E-post"
                      type="email"
                    />
                    <TextField
                      name="contactPhone"
                      label="Mobilnummer"
                      type="number"
                    />
                  </div>
                </div>

                {/* Icon Toggles Section */}
                <div className="card mb-4">
                  <div className="card-header fw-bold">Aktiver Ikoner</div>
                  <div className={`card-body ${styles.iconGrid}`}>
                    {icons.map((icon, index) => (
                      <div key={index} className={styles.iconItem}>
                        <p>{icon.name}</p>
                        <icon.component width="80" height="80" />
                        {icon.type === "toggle" ? (
                          <FormCheck
                            type="switch"
                            className="custom-form"
                            id={`icon-toggle-${index}`}
                            checked={iconStates[iconStateMapping[icon.name]]}
                            onChange={() =>
                              handleToggle(iconStateMapping[icon.name])
                            }
                          />
                        ) : (
                          <>
                            {icon.name.includes("Menn") && (
                              <TextField
                                name="menCapacity"
                                type="number"
                                className="form-control mb-3"
                              />
                            )}
                            {icon.name.includes("Kvinner") && (
                              <TextField
                                name="womenCapacity"
                                type="number"
                                className="form-control mb-3"
                              />
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Submit button */}
                <div className="d-grid pb-4">
                  <Button
                    variant="dark"
                    type="submit"
                    className={styles.largeButton}
                  >
                    Lagre endringer
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
