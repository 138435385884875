import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import useFetch from "hooks/useFetch";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import styles from "./EventPage.module.scss";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";

interface EventForm {
  tittel: string;
  tekst: string;
  start: string;
  end: string;
  plakat?: File | null;
  recurring: boolean;
  activated: boolean;
}

interface Event {
  id: number;
  tittel: string;
  tekst: string;
  start: string;
  end: string;
  plakat?: string;
  recurring: boolean;
  activated: boolean;
}

const validationSchema = Yup.object().shape({
  tittel: Yup.string().required("Aktivitet overskrift er påkrevd"),
  tekst: Yup.string().required("Aktivitet beskrivelse er påkrevd"),
  start: Yup.string()
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Ugyldig tid")
    .required("Start tid er påkrevd"),
  end: Yup.string()
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Ugyldig tid")
    .required("Slutt tid er påkrevd"),
  plakat: Yup.mixed(),
});

const EventPage: React.FC = () => {
  const { selectedOrgNumber } = useAuth();
  const { data, isLoading, error, execute } = useFetch<any>();
  const {
    data: eventsData,
    isLoading: isEventsLoading,
    execute: fetchEvents,
  } = useFetch<Event[]>();

  const [events, setEvents] = useState<Event[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [editImagePreview, setEditImagePreview] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { execute: updateEvent } = useFetch<any>();

  const fetchData = async () => {
    const response = await fetchEvents(
      `/api/v1/event/org/${selectedOrgNumber}`,
      "GET",
    );
    if (response) {
      setEvents(response);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOrgNumber]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleEditFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      setEditImagePreview(URL.createObjectURL(file));
    }
  };

  const handleTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: Function,
    field: string,
  ) => {
    let value = e.target.value.replace(/[^0-9]/g, "");

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    if (value.length > 2) {
      value = value.slice(0, 2) + ":" + value.slice(2);
    }

    setFieldValue(field, value);
  };

  const combineDateAndTime = (
    date: Date | null,
    time: string,
  ): string | null => {
    if (!date || !time) return null;
    const [hours, minutes] = time.split(":");
    const dateWithTime = new Date(date);
    dateWithTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);

    const year = dateWithTime.getFullYear();
    const month = String(dateWithTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateWithTime.getDate()).padStart(2, "0");
    const hour = String(dateWithTime.getHours()).padStart(2, "0");
    const minute = String(dateWithTime.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  const formatTimeWithDate = (dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${hours}:${minutes} - ${day}/${month}/${year}`;
  };

  const formatTime = (isoString: string) => {
    const date = new Date(isoString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const handleImageClick = (plakat: string) => {
    setSelectedImage(plakat);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditImagePreview(null);
  };

  const handleSubmit = async (values: EventForm, { resetForm }: any) => {
    const formattedStart = combineDateAndTime(selectedStartDate, values.start);
    const formattedEnd = combineDateAndTime(selectedEndDate, values.end);

    const eventRequestDto = {
      start: formattedStart || "",
      end: formattedEnd || "",
      tittel: values.tittel,
      tekst: values.tekst,
      mosqueOrgNr: selectedOrgNumber,
      recurring: values.recurring,
      activated: values.activated,
    };

    const formData = new FormData();

    if (values.plakat) {
      formData.append("file", values.plakat);
    }

    formData.append("eventRequestDto", JSON.stringify(eventRequestDto));

    try {
      const response = await execute(
        "/api/v1/event/addWithImage",
        "POST",
        formData,
      );

      resetForm();
      setImagePreview(null);
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setSelectedImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setEvents((prevEvents) => [...prevEvents, response]);

      fetchData();
    } catch (error: any) {
      console.error("Error:", error.response || error.message || error);
    }
  };

  const deleteEvent = (id: number) => {
    execute(`/api/v1/event/${id}`, "DELETE")
      .then(() => {
        const updatedEvents = events.filter((event) => event.id !== id);
        setEvents(updatedEvents);
      })
      .catch((error) => {
        console.error("Failed to delete event:", error);
      });
  };

  const openEditModal = (event: Event) => {
    setEditingEvent(event);
    setShowEditModal(true);
    setSelectedStartDate(new Date(event.start));
    setSelectedEndDate(new Date(event.end));
    setEditImagePreview(event.plakat || null);
  };

  const handleSaveEdit = async (values: EventForm) => {
    if (!editingEvent) return;

    const formattedStart = combineDateAndTime(selectedStartDate, values.start);
    const formattedEnd = combineDateAndTime(selectedEndDate, values.end);

    const eventRequestDto = {
      tittel: values.tittel,
      tekst: values.tekst,
      start: formattedStart || "",
      end: formattedEnd || "",
      mosqueOrgNr: selectedOrgNumber,
      recurring: values.recurring,
      activated: values.activated,
    };

    const formData = new FormData();
    formData.append("eventRequestDto", JSON.stringify(eventRequestDto));

    if (values.plakat) {
      formData.append("file", values.plakat);
    }

    try {
      await updateEvent(
        `/api/v1/event/${selectedOrgNumber}/${editingEvent.id}`,
        "PATCH",
        formData,
      );

      setEvents((prev) =>
        prev.map((event) =>
          event.id === editingEvent.id
            ? {
                ...event,
                tittel: values.tittel,
                tekst: values.tekst,
                activated: values.activated,
                start: formattedStart || event.start,
                end: formattedEnd || event.end,
                plakat: values.plakat
                  ? URL.createObjectURL(values.plakat)
                  : event.plakat,
              }
            : event,
        ),
      );

      setShowEditModal(false);
      setEditingEvent(null);
      fetchData();
    } catch (error) {
      console.error("Failed to update event", error);
    }
  };

  return (
    <div className={styles.eventPageContainer}>
      <Card className={styles.eventCard}>
        <Card.Body>
          <h2 className="text-center">Opprett Aktivitet</h2>

          <Formik
            initialValues={{
              tittel: editingEvent?.tittel || "",
              tekst: editingEvent?.tekst || "",
              start: editingEvent ? formatTime(editingEvent.start) : "",
              end: editingEvent ? formatTime(editingEvent.end) : "",
              recurring: editingEvent?.recurring || false,
              activated: editingEvent?.activated || true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty, setFieldValue, values }) => (
              <FormikForm>
                <Form.Group controlId="formTittel" className={styles.formGroup}>
                  <Form.Label>Aktivitet overskrift*</Form.Label>
                  <Field
                    name="tittel"
                    className="form-control"
                    placeholder="Skriv overskrift..."
                  />
                  <ErrorMessage
                    name="tittel"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formTekst" className={styles.formGroup}>
                  <Form.Label>Aktivitet beskrivelse*</Form.Label>
                  <Field
                    name="tekst"
                    as="textarea"
                    className="form-control"
                    rows={3}
                    placeholder="Skriv beskrivelse..."
                  />
                  <ErrorMessage
                    name="tekst"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formStart" className={styles.formGroup}>
                  <Form.Label>Start tid*</Form.Label>
                  <Field
                    name="start"
                    type="text"
                    className="form-control"
                    placeholder="HH:MM"
                    value={values.start}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTimeChange(e, setFieldValue, "start")
                    }
                  />
                  <ErrorMessage
                    name="start"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formDate" className={styles.formGroup}>
                  <Form.Label>Start Dato*</Form.Label>
                  <DatePicker
                    selected={selectedStartDate}
                    onChange={(date) => setSelectedStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="formEnd" className={styles.formGroup}>
                  <Form.Label>Slutt tid*</Form.Label>
                  <Field
                    name="end"
                    type="text"
                    className="form-control"
                    placeholder="HH:MM"
                    value={values.end}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTimeChange(e, setFieldValue, "end")
                    }
                  />
                  <ErrorMessage
                    name="end"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formDate" className={styles.formGroup}>
                  <Form.Label>Slutt Dato*</Form.Label>
                  <DatePicker
                    selected={selectedEndDate}
                    onChange={(date) => setSelectedEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="formPlakat" className={styles.formGroup}>
                  <Form.Label>Overskriftsbilde*</Form.Label>
                  <input
                    name="plakat"
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue(
                        "plakat",
                        event.currentTarget.files?.[0] || null,
                      );
                      handleFileChange(event);
                    }}
                  />
                  {imagePreview && (
                    <div className={styles.imagePreview}>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="img-fluid"
                      />
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="formActivated">
                  <Form.Check
                    type="switch"
                    label="Vis dette i appen"
                    name="activated"
                    checked={values.activated}
                    onChange={(e) =>
                      setFieldValue("activated", e.target.checked)
                    }
                  />
                </Form.Group>

                {isLoading ? (
                  <Button variant="primary" disabled>
                    Laster opp...
                  </Button>
                ) : (
                  <Button
                    variant="dark"
                    type="submit"
                    disabled={!(isValid && dirty)}
                    className={styles.largeButton}
                  >
                    Opprett Aktivitet
                  </Button>
                )}

                {error && (
                  <Alert variant="danger" className="mt-3">
                    Error: {error.text}
                  </Alert>
                )}
                {data && (
                  <Alert variant="success" className="mt-3">
                    Aktivitet opprettet!
                  </Alert>
                )}
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <h3>Alle Aktiviteter</h3>
      <div className={styles.eventListContainer}>
        {isEventsLoading ? (
          <Spinner animation="border" />
        ) : error ? (
          <Alert variant="danger">Feil under lasting av aktiviteter</Alert>
        ) : (
          <div className={styles.eventList}>
            {events.length > 0 ? (
              events.map((event) => (
                <div key={event.id} className={styles.eventItem}>
                  <div
                    className={`${styles.eventContent} ${!event.activated ? styles.inactiveEvent : ""}`}
                  >
                    <div className={styles.titleHeader}>
                      <h3 className={styles.eventTitle}>{event.tittel}</h3>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {event.activated
                              ? "Vises i appen"
                              : "Skjult i appen"}
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          icon={event.activated ? faEye : faEyeSlash}
                          style={{
                            marginLeft: "8px",
                          }}
                          title={
                            event.activated ? "Vises i appen" : "Skjult i appen"
                          }
                        />
                      </OverlayTrigger>
                    </div>
                    <p className={styles.eventDescription}>
                      {truncateText(event.tekst, 100)}
                    </p>
                    <div className={styles.eventDateTime}>
                      <span>
                        <strong>Start:</strong>{" "}
                        {formatTimeWithDate(event.start)}
                      </span>
                      <span>
                        <strong>Slutt:</strong> {formatTimeWithDate(event.end)}
                      </span>
                    </div>
                    {event.plakat && event.plakat.trim() !== "" && (
                      <div className={styles.eventPlakat}>
                        <img
                          src={event.plakat}
                          alt={`${event.tittel} Plakat`}
                          onClick={() => handleImageClick(event.plakat || "")}
                          className={styles.plakatImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.eventActions}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => openEditModal(event)}
                      className={styles.editIcon}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => deleteEvent(event.id)}
                      className={styles.deleteIcon}
                    />
                  </div>
                </div>
              ))
            ) : (
              <NoContentPlaceholder message="Ingen aktiviteter funnet" />
            )}

            <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Rediger Aktivitet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {editingEvent && (
                  <Formik
                    initialValues={{
                      tittel: editingEvent.tittel,
                      tekst: editingEvent.tekst,
                      start: editingEvent ? formatTime(editingEvent.start) : "",
                      end: editingEvent ? formatTime(editingEvent.end) : "",
                      recurring: editingEvent.recurring,
                      activated: editingEvent.activated,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSaveEdit}
                  >
                    {({ isValid, dirty, values, setFieldValue }) => (
                      <FormikForm>
                        <Form.Group
                          controlId="formTittel"
                          className={styles.formGroup}
                        >
                          <Form.Label>Tittel*</Form.Label>
                          <Field
                            name="tittel"
                            className="form-control"
                            placeholder="Rediger plakat tittel"
                          />
                          <ErrorMessage
                            name="tittel"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formTekst"
                          className={styles.formGroup}
                        >
                          <Form.Label>Tekst*</Form.Label>
                          <Field
                            name="tekst"
                            as="textarea"
                            className="form-control"
                            rows={5}
                            placeholder="Rediger plakat tekst"
                          />
                          <ErrorMessage
                            name="tekst"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formStart"
                          className={styles.formGroup}
                        >
                          <Form.Label>Start tid*</Form.Label>
                          <Field
                            name="start"
                            type="text"
                            className="form-control"
                            placeholder="HH:MM"
                            value={values.start}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleTimeChange(e, setFieldValue, "start")}
                          />
                          <ErrorMessage
                            name="start"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formDate"
                          className={styles.formGroup}
                        >
                          <Form.Label>Start Dato*</Form.Label>
                          <DatePicker
                            selected={selectedStartDate}
                            onChange={(date) => setSelectedStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formEnd"
                          className={styles.formGroup}
                        >
                          <Form.Label>Slutt tid*</Form.Label>
                          <Field
                            name="end"
                            type="text"
                            className="form-control"
                            placeholder="HH:MM"
                            value={values.end}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleTimeChange(e, setFieldValue, "end")}
                          />
                          <ErrorMessage
                            name="end"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formDate"
                          className={styles.formGroup}
                        >
                          <Form.Label>Slutt Dato*</Form.Label>
                          <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => setSelectedEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                          />
                        </Form.Group>

                        <Form.Group
                          controlId="formPlakat"
                          className={styles.formGroup}
                        >
                          <Form.Label>Overskriftsbilde</Form.Label>
                          <input
                            name="plakat"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={(event) => {
                              setFieldValue(
                                "plakat",
                                event.currentTarget.files?.[0] || null,
                              );
                              handleEditFileChange(event);
                            }}
                          />
                          {editImagePreview && (
                            <div className={styles.imagePreview}>
                              <img
                                src={editImagePreview}
                                alt="Image Preview"
                                className="img-fluid"
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group controlId="formActivated">
                          <Form.Check
                            type="switch"
                            label="Vis dette i appen"
                            name="activated"
                            checked={values.activated}
                            onChange={(e) =>
                              setFieldValue("activated", e.target.checked)
                            }
                          />
                        </Form.Group>

                        <Button
                          variant="dark"
                          type="submit"
                          className={styles.largeButton}
                          disabled={!isValid}
                        >
                          Lagre Endringer
                        </Button>
                      </FormikForm>
                    )}
                  </Formik>
                )}
              </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="text-center">
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Full-size Plakat"
                    className="img-fluid"
                    style={{ maxHeight: "80vh", width: "auto" }}
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPage;
