import { useAuth } from "AuthProvider";
import useFetch from "hooks/useFetch";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Alert } from "react-bootstrap";
import Header from "stories/Header/Header";
import PrayerTimesSkeleton from "./PrayerTimesSkeleton/PrayerTimesSkeleton";
import styles from "./prayerTimes.module.scss";

interface PrayerTimes {
  date: string;
  fajr: string;
  duhr: string;
  asr: string;
  maghrib: string;
  isha: string;
  asr_1x_shadow: string;
  asr_2x_shadow: string;
}

const PrayerTimesPage: React.FC = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [prayerTimesData, setPrayerTimesData] = useState<PrayerTimes[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const { execute, isLoading, error } = useFetch<PrayerTimes[]>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { execute: fetchMosqueData } = useFetch<any>();
  const {
    locationId,
    prayerTimeTableId,
    selectedOrgNumber,
    setLocationId,
    setPrayerTimeTableId,
    isSuperAdmin,
  } = useAuth();

  const getMonthName = (date: Date) =>
    new Intl.DateTimeFormat("default", { month: "long" }).format(date);

  const fetchPrayerTimes = async () => {
    if (!locationId || !prayerTimeTableId) return;

    const month = (currentMonth.getMonth() + 1).toString().padStart(2, "0");
    const endpoint = `/api/v1/prayerTimes/${locationId}/${prayerTimeTableId}/${currentYear}/${month}`;

    const result = await execute(endpoint, "GET");
    setPrayerTimesData(result || []);
  };

  const fetchMosqueInfo = async () => {
    if (!selectedOrgNumber) {
      console.error("selectedOrgNumber is null or undefined.");
      return;
    }

    try {
      const mosqueResult = await fetchMosqueData(
        `/api/v1/mosques/${selectedOrgNumber}`,
        "GET",
      );

      if (mosqueResult) {
        if (mosqueResult.locationId) {
          setLocationId(mosqueResult.locationId);
        } else {
          console.error("locationId is missing in mosqueResult:", mosqueResult);
        }

        if (mosqueResult.prayerTimeTable) {
          setPrayerTimeTableId(mosqueResult.prayerTimeTable);
        } else {
          console.error(
            "prayerTimeTable is missing in mosqueResult:",
            mosqueResult,
          );
        }
      }
    } catch (error) {
      console.error("Failed to fetch mosque info:", error);
    }
  };

  const fetchCsvData = async () => {
    if (!locationId || !prayerTimeTableId) return;

    const url = `/api/v1/prayerTimes/${locationId}/${prayerTimeTableId}/${currentYear}/download-csv`;
    try {
      const result = await execute(url, "GET");
      setCsvData(result || []);
    } catch (error) {
      console.error("Failed to fetch CSV data", error);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const uploadUrl = `/api/v1/prayerTimes/${locationId}/${prayerTimeTableId}/${currentYear}/upload-csv`;

      try {
        await execute(uploadUrl, "POST", formData);
        console.log("File uploaded successfully");
        fetchPrayerTimes();
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  useEffect(() => {
    if (selectedOrgNumber) {
      fetchMosqueInfo();
    }
  }, [selectedOrgNumber]);

  useEffect(() => {
    fetchPrayerTimes();
    fetchCsvData();
  }, [currentMonth, currentYear, locationId, prayerTimeTableId]);

  useEffect(() => {
    fetchCsvData();
  }, [locationId, prayerTimeTableId, currentYear]);

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = new Date(currentMonth);
    newMonth.setMonth(parseInt(event.target.value));
    setCurrentMonth(newMonth);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentYear(parseInt(event.target.value));
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const renderPrayerTimes = () => (
    <div className={styles.tableContainer}>
      <table className={styles.prayerTimesTable}>
        <thead>
          <tr>
            <th>Dato</th>
            <th>Fajr</th>
            <th>Duhr</th>
            <th>Asr</th>
            <th>Asr1x</th>
            <th>Asr2x</th>
            <th>Maghrib</th>
            <th>Isha</th>
          </tr>
        </thead>
        <tbody>
          {prayerTimesData.map((times) => (
            <tr key={times.date}>
              <td>
                {new Intl.DateTimeFormat("default", {
                  day: "numeric",
                  month: "long",
                }).format(new Date(times.date))}
              </td>
              <td>{times.fajr}</td>
              <td>{times.duhr}</td>
              <td>{times.asr}</td>
              <td>{times.asr_1x_shadow}</td>
              <td>{times.asr_2x_shadow}</td>
              <td>{times.maghrib}</td>
              <td>{times.isha}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderError = () =>
    error && (
      <Alert variant="danger" className="mt-3">
        <h4>
          Ingen bønnetider funnet for ${getMonthName(currentMonth)}{" "}
          {currentYear}.
        </h4>
      </Alert>
    );

  return (
    <div className={`container ${styles.prayerTimesPage}`}>
      <Header title="Bønnetider" />
      <div className="d-flex align-items-center justify-content-end mb-3 pt-2">
        <CSVLink
          data={csvData}
          filename={`prayer_times_${locationId}_${prayerTimeTableId}_${currentYear}.csv`}
          className="btn btn-primary"
        >
          Eksporter til CSV
        </CSVLink>
        {/* {isSuperAdmin && (
          <>
            <button
              className="btn btn-secondary ms-2"
              onClick={triggerFileInput}
            >
              Importer CSV
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv"
              onChange={(event) => handleFileUpload(event)}
            />
          </>
        )} */}
      </div>
      <div className={styles.monthContainer}>
        <div className="d-flex align-items-center flex-wrap">
          <h3 className="me-3">Velg måned og år:</h3>
          <div className="row g-2 align-items-center flex-nowrap">
            <div className="col-auto">
              <select
                className="form-select"
                value={currentMonth.getMonth()}
                onChange={handleMonthChange}
              >
                {Array.from({ length: 12 }, (_, index) => (
                  <option key={index} value={index}>
                    {new Intl.DateTimeFormat("default", {
                      month: "long",
                    }).format(new Date(0, index))}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <select
                className="form-select"
                value={currentYear}
                onChange={handleYearChange}
              >
                {Array.from({ length: 5 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {isLoading ? (
            <PrayerTimesSkeleton />
          ) : error ? (
            renderError()
          ) : (
            renderPrayerTimes()
          )}
        </div>
      </div>
    </div>
  );
};

export default PrayerTimesPage;
