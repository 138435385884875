export enum RoutePaths {
  Login = "/innlogging",
  MosqueRequestForm = "/ny-moske",
  SuperAdminMosqueSelection = "/velg-moske",
  Root = "/",
  Jamat = "/jamat",
  PrayerTimes = "/bonnetider",
  SpecialPrayerSettings = "/spesielle-bonnetider",
  Notification = "/push-meldinger",
  Event = "/aktiviteter",
  Blog = "/blogg",
  Posters = "/tv-admin",
  Information = "/informasjon",
  Campaign = "/kampanje",
  MosqueAdmin = "/moske-admin",
  Dua = "/dua",
  HijriDates = "/hijri-datoer",
  Users = "/brukere",
  SuperAdminPrayerTimes = "/bonne-tider-admin",
  NamesOfAllah = "/allahs-navn",
  Expressions = "/vanlige-uttrykk",
  GlobalPush = "/global-push-melding",
  NotFound = "*",
}
