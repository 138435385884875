import { ErrorMessage, Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Modal } from "react-bootstrap";
import Header from "stories/Header/Header";
import * as Yup from "yup";
import styles from "./AddMosquePage.module.scss";
import NoContentPlaceholder from "stories/NoContentPlaceholder/NoContentPlaceholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMosque } from "@fortawesome/free-solid-svg-icons";

interface NewMosqueFormValues {
  logo?: string;
  orgNr: string;
  name: string;
  orgName: string;
  prayerTimeTable?: number;
  districtCode?: number;
  streetAddress: string;
  postalCode: string;
  city: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  locationId?: number;
  asrMethod?: string;
}

interface Location {
  id: number;
  name: string;
  prayerTimesTables: Array<{ id: number; name: string }>;
}

interface Mosque {
  id: number;
  logo: string;
  orgNr: string;
  name: string;
  orgName: string;
  districtCode: number;
  mapOnly: boolean;
  streetAddress: string;
  postalCode: string;
  city: string;
  lat: number;
  lon: number;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  locationId: number;
  prayerTimeTable: number;
  prayerTimeTableName: string;
  locationName: string;
  asrMethod: string;
  merchantId: string;
  cached: boolean;
  cacheTimestamp: string;
}

const validationSchema = Yup.object({
  orgNr: Yup.string().required("Organisasjonsnummer er påkrevd"),
  name: Yup.string().required("Moské navn er påkrevd"),
  orgName: Yup.string().required("Organisasjonsnavn er påkrevd"),
  streetAddress: Yup.string().required("Adresse er påkrevd"),
  postalCode: Yup.string().required("Postnummer er påkrevd"),
  city: Yup.string().required("Sted er påkrevd"),
  contactName: Yup.string().required("Kontaktperson er påkrevd"),
  contactPhone: Yup.string().required("Telefonnummer er påkrevd"),
  contactEmail: Yup.string()
    .email("Ugyldig e-post")
    .required("E-post er påkrevd"),
});

const AddMosquePage: React.FC = () => {
  const { execute } = useFetch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [prayerTimesTables, setPrayerTimesTables] = useState<
    Array<{ id: number; name: string }>
  >([]);
  const [mosques, setMosques] = useState<Mosque[]>([]);
  const [selectedMosque, setSelectedMosque] = useState<Mosque | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [pendingRequests, setPendingRequests] = useState<Mosque[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [pendingRequestMosque, setPendingRequestMosque] =
    useState<Mosque | null>(null);

  useEffect(() => {
    const fetchMosques = async () => {
      const mosquesResult = (await execute(
        "/api/v1/mosques",
        "GET",
      )) as Mosque[];
      if (mosquesResult) {
        const sortedMosques = mosquesResult.sort((a: Mosque, b: Mosque) =>
          a.orgName.localeCompare(b.orgName),
        );
        setMosques(sortedMosques);
      }
    };
    fetchMosques();
  }, []);

  useEffect(() => {
    const fetchPendingRequests = async () => {
      try {
        const result = (await execute(
          "/api/v1/mosques/approval/pending",
          "GET",
        )) as Mosque[];
        setPendingRequests(result || []);
      } catch (error) {
        console.error("Feil ved henting av ventende forespørsler:", error);
      }
    };
    fetchPendingRequests();
  }, []);

  const handleMosqueSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const mosqueId = parseInt(event.target.value, 10);
    const mosque = mosques.find((m) => m.id === mosqueId) || null;
    setSelectedMosque(mosque);
  };

  const handleDeleteMosque = async () => {
    if (selectedMosque) {
      await execute(`/api/v1/mosques/${selectedMosque.id}`, "DELETE");
      setMosques(mosques.filter((m) => m.id !== selectedMosque.id));
      setSelectedMosque(null);
      setShowDeleteModal(false);
      alert("Moské slettet!");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => setLogoUrl(reader.result as string);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleApproveRequest = async (id: number) => {
    try {
      await execute(`/api/v1/mosques/approval/approve/${id}`, "POST");
      setPendingRequests(pendingRequests.filter((req) => req.id !== id));
      setSuccessMessage("Moskéen er godkjent!");
      setPendingRequestMosque(null);
      setShowRequestModal(false);
    } catch (error) {
      setErrorMessage("Kunne ikke godkjenne forespørselen.");
    }
  };

  const handleRejectRequest = async (id: number) => {
    try {
      await execute(`/api/v1/mosques/approval/reject/${id}`, "DELETE");
      setPendingRequests(pendingRequests.filter((req) => req.id !== id));
      setErrorMessage("Moskéforespørselen er avvist.");
      setPendingRequestMosque(null);
      setShowRequestModal(false);
    } catch (error) {
      setErrorMessage("Kunne ikke avvise forespørselen.");
    }
  };

  const handleSubmit = async (values: NewMosqueFormValues) => {
    const formData = new FormData();
    const mosquePayload = {
      id: 0,
      orgNr: values.orgNr,
      name: values.name,
      orgName: values.orgName,
      districtCode: values.districtCode || 0,
      mapOnly: true,
      streetAddress: values.streetAddress,
      postalCode: values.postalCode,
      city: values.city,
      lat: 0,
      lon: 0,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      contactEmail: values.contactEmail,
      locationId: values.locationId || 0,
      prayerTimeTable: values.prayerTimeTable || 0,
      prayerTimeTableName: "defaultName",
      locationName: "defaultLocation",
      asrMethod: values.asrMethod || "standard",
      merchantId: "defaultMerchant",
      cached: true,
      cacheTimestamp: new Date().toISOString(),
    };

    formData.append("mosqueData", JSON.stringify(mosquePayload));

    try {
      await execute("/api/v1/mosques", "POST", mosquePayload);
      alert("Moské opprettet!");
    } catch (error) {
      alert("En feil oppstod under oppretting av moské.");
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationResult = (await execute(
          "/api/v1/locations",
          "GET",
        )) as Location[];
        if (locationResult) {
          const sortedLocations = locationResult.sort(
            (a: Location, b: Location) => a.name.localeCompare(b.name),
          );
          setLocations(sortedLocations);
          console.log("Lokasjoner hentet:", sortedLocations);
        }
      } catch (error) {
        console.error("Feil ved henting av lokasjoner:", error);
      }
    };

    fetchLocations();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={`container mt-5 ${styles.container}`}>
        <Header title="Ventende Forespørsler" />
        <div className="mb-4">
          {pendingRequests.length > 0 ? (
            <>
              {pendingRequests.map((request) => (
                <Button
                  key={request.id}
                  className={`mb-2 w-100 text-start ${styles.pendingRequestButton}`}
                  onClick={() => {
                    setPendingRequestMosque(request);
                    setShowRequestModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMosque}
                    className="me-2"
                    style={{ color: "#BEBEBE" }}
                  />
                  {request.name} - {request.orgNr}
                </Button>
              ))}

              <Modal
                show={showRequestModal}
                onHide={() => {
                  setShowRequestModal(false);
                  setPendingRequestMosque(null);
                }}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <FontAwesomeIcon
                      icon={faMosque}
                      className="me-2"
                      style={{ color: "#BEBEBE", paddingRight: "5px" }}
                    />
                    Moské Forespørsel:{" "}
                    {pendingRequestMosque?.name ||
                      pendingRequestMosque?.orgName}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {pendingRequestMosque && (
                    <div>
                      <p>
                        <strong>Organisasjonsnummer:</strong>{" "}
                        {pendingRequestMosque.orgNr}
                      </p>
                      <p>
                        <strong>Organisasjonsnavn:</strong>{" "}
                        {pendingRequestMosque.orgName}
                      </p>
                      <p>
                        <strong>Kontaktperson:</strong>{" "}
                        {pendingRequestMosque.contactName} (
                        {pendingRequestMosque.contactEmail})
                      </p>
                      <p>
                        <strong>Telefon:</strong>{" "}
                        {pendingRequestMosque.contactPhone}
                      </p>
                      <p>
                        <strong>Adresse:</strong>{" "}
                        {pendingRequestMosque.streetAddress},{" "}
                        {pendingRequestMosque.postalCode}{" "}
                        {pendingRequestMosque.city}
                      </p>
                      <p>
                        <strong>Lokasjon ID:</strong>{" "}
                        {pendingRequestMosque.locationId}
                      </p>
                      <p>
                        <strong>Bønnetid Tabell ID:</strong>{" "}
                        {pendingRequestMosque.prayerTimeTable}
                      </p>
                      <p>
                        <strong>Asr Metode:</strong>{" "}
                        {pendingRequestMosque.asrMethod}
                      </p>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="success"
                    onClick={() =>
                      pendingRequestMosque &&
                      handleApproveRequest(pendingRequestMosque.id)
                    }
                  >
                    Godkjenn
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() =>
                      pendingRequestMosque &&
                      handleRejectRequest(pendingRequestMosque.id)
                    }
                  >
                    Avvis
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <NoContentPlaceholder
              variant="compact"
              message="Ingen ventende forespørsler å vise."
            />
          )}

          <br />

          {successMessage && (
            <Alert
              variant="success"
              onClose={() => setSuccessMessage(null)}
              dismissible
            >
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage(null)}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
        </div>

        <Header title="Opprett Ny Moské" />
        <br />
        <Formik
          initialValues={{
            logo: "",
            orgNr: "",
            name: "",
            orgName: "",
            prayerTimeTable: 0,
            districtCode: 0,
            streetAddress: "",
            postalCode: "",
            city: "",
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            asrMethod: "",
            locationId: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="card mb-4">
                <div className="card-header fw-bold">Last opp logo</div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="logoUpload">Last opp logo:</label>
                    <input
                      type="file"
                      id="logoUpload"
                      className="form-control mb-3"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-header fw-bold">Moské Informasjon</div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="locationId">Lokasjon:</label>
                    <Field
                      as="select"
                      id="locationId"
                      name="locationId"
                      className="form-control mb-3"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedLocation = locations.find(
                          (location) =>
                            location.id === parseInt(e.target.value, 10),
                        );
                        setFieldValue("locationId", e.target.value);
                        setPrayerTimesTables(
                          selectedLocation?.prayerTimesTables || [],
                        );
                      }}
                    >
                      <option value="">Velg en lokasjon</option>
                      {locations.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="locationId"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="prayerTimeTable">Bønn Tidstabell:</label>
                    <Field
                      as="select"
                      id="prayerTimeTable"
                      name="prayerTimeTable"
                      className="form-control mb-3"
                      disabled={prayerTimesTables.length === 0}
                    >
                      <option value="">Velg en tidstabell</option>
                      {prayerTimesTables.map((table) => (
                        <option key={table.id} value={table.id}>
                          {table.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="prayerTimeTable"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="orgNr">Organisasjonsnummer:</label>
                    <Field
                      type="text"
                      id="orgNr"
                      name="orgNr"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="orgNr"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Moské Navn:</label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="orgName">Organisasjonsnavn:</label>
                    <Field
                      type="text"
                      id="orgName"
                      name="orgName"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="orgName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-header fw-bold">Adresse Informasjon</div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="streetAddress">Adresse:</label>
                    <Field
                      type="text"
                      id="streetAddress"
                      name="streetAddress"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="streetAddress"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postalCode">Postnummer:</label>
                    <Field
                      type="number"
                      id="postalCode"
                      name="postalCode"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="postalCode"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="city">Sted:</label>
                    <Field
                      type="text"
                      id="city"
                      name="city"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <div className="card-header fw-bold">Kontakt Informasjon</div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="contactName">Kontaktperson:</label>
                    <Field
                      type="text"
                      id="contactName"
                      name="contactName"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="contactName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="contactPhone">Telefonnummer:</label>
                    <Field
                      type="number"
                      id="contactPhone"
                      name="contactPhone"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="contactPhone"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="contactEmail">E-post:</label>
                    <Field
                      type="email"
                      id="contactEmail"
                      name="contactEmail"
                      className="form-control mb-3"
                    />
                    <ErrorMessage
                      name="contactEmail"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>

              <div className="d-grid">
                <Button
                  variant="dark"
                  type="submit"
                  className={styles.largeButton}
                >
                  Lagre moské
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <br />
      {/* Dropdown to select mosque */}
      <div className="form-group mt-4">
        <Header title="Slett Moskè" />
        <br />
        <label htmlFor="mosqueSelect" style={{ paddingBottom: "20px" }}>
          Velg en moské for å vise detaljer:
        </label>
        <select
          id="mosqueSelect"
          className="form-control mb-3"
          onChange={handleMosqueSelect}
          value={selectedMosque?.id || ""}
        >
          <option value="">Velg en moské</option>
          {mosques.map((mosque) => (
            <option key={mosque.id} value={mosque.id}>
              {(mosque.name || mosque.orgName).toUpperCase()}
            </option>
          ))}
        </select>
      </div>

      {selectedMosque && (
        <Card className="mb-4">
          <Card.Header>
            {selectedMosque.name || selectedMosque.orgName}
          </Card.Header>
          <Card.Body>
            <p>
              <strong>Org Nr:</strong> {selectedMosque.orgNr}
            </p>
            <p>
              <strong>Org Navn:</strong> {selectedMosque.orgName}
            </p>
            <p>
              <strong>Adresse:</strong> {selectedMosque.streetAddress},{" "}
              {selectedMosque.city} {selectedMosque.postalCode}
            </p>
            <p>
              <strong>Kontakt:</strong> {selectedMosque.contactName}
            </p>
            <p>
              <strong>Telefon:</strong> {selectedMosque.contactPhone}
            </p>
            <p>
              <strong>E-mail:</strong> {selectedMosque.contactEmail}
            </p>
            <p>
              <strong>Postkode:</strong> {selectedMosque.districtCode}
            </p>
            <p>
              <strong>Lokasjon:</strong> {selectedMosque.locationName}
            </p>
            <p>
              <strong>Bønnetid Tabell:</strong>{" "}
              {selectedMosque.prayerTimeTableName}
            </p>
            <p>
              <strong>Asr Metode:</strong> {selectedMosque.asrMethod}
            </p>
          </Card.Body>
        </Card>
      )}

      {selectedMosque && (
        <>
          <Button
            variant="danger"
            onClick={() => setShowDeleteModal(true)}
            className="mb-4"
          >
            Slett Moské
          </Button>

          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                Er du sikker på at du vil slette moskéen?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                For å bekrefte sletting, skriv inn moskéens navn:{" "}
                <strong>
                  {selectedMosque?.orgName || selectedMosque?.orgNr}
                </strong>
              </p>
              <input
                type="text"
                className="form-control mb-3"
                value={confirmationText}
                onChange={(e) => setConfirmationText(e.target.value)}
                placeholder="Skriv inn moskéens navn for å bekrefte"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                Avbryt
              </Button>
              <Button
                variant="danger"
                onClick={handleDeleteMosque}
                disabled={confirmationText !== selectedMosque?.orgName}
              >
                Bekreft Sletting
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AddMosquePage;
