import { RoutePaths } from "enums/RoutePaths";
import LayoutWithSideMenu from "pages/LayoutWithSideMenu/LayoutWithSideMenu";
import Login from "pages/Login/Login";
import SuperAdminMosqueSelection from "pages/SuperAdminMosqueSelection/SuperAdminMosqueSelection";
import GlobalPushPage from "pages/SuperAdminPages/GlobalPushPage/GlobalPushPage";
import HijriPage from "pages/SuperAdminPages/HijriPage/HijriPage";
import SuperAdminPrayerTimesPage from "pages/SuperAdminPages/SuperAdminPrayerTimesPage/SuperAdminPrayerTimesPage";
import UsersPage from "pages/SuperAdminPages/UsersPage/UsersPage";
import SuperAdminSideMenu from "pages/SuperAdminSideMenu/SuperAdminSideMenu";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop";
import AuthProvider, { useAuth } from "./AuthProvider";
import AdminPage from "./pages/AdminPage/AdminPage";
import BlogPage from "./pages/BlogPage/BlogPage";
import CampaignPage from "./pages/CampaignPage/CampaignPage";
import EventPage from "./pages/EventPage/EventPage";
import InformationPage from "./pages/InformationPage/InformationPage";
import JamatPage from "./pages/JamatPage/JamatPage";
import NotificationPage from "./pages/NotificationPage/NotificationPage";
import PostersPage from "./pages/PostersPage/PostersPage";
import PrayerTimesPage from "./pages/PrayerTimes/PrayerTimes";
import SpecialPrayerSettings from "./pages/SpecialPrayerSettings/SpecialPrayerSettings";
import AddMosquePage from "./pages/SuperAdminPages/AddMosquePage/AddMosquePage";
import DuaPage from "pages/SuperAdminPages/DuaPage/DuaPage";
import NamesOfAllahPage from "pages/SuperAdminPages/NamesOfAllah/NamesOfAllahPage";
import ExpressionsPage from "pages/SuperAdminPages/ExpressionsPage/ExpressionsPage";
import CreateMosqueRequestPage from "pages/CreateMosqueRequestPage/CreateMosqueRequestPage";

const ProtectedRoutes: React.FC = () => {
  const { isSuperAdmin, selectedOrgNumber, superAdminMode } = useAuth();

  return (
    <Routes>
      {/* Super admin mosque selection route */}
      <Route
        path={RoutePaths.SuperAdminMosqueSelection}
        element={
          isSuperAdmin ? (
            <SuperAdminMosqueSelection />
          ) : (
            <Navigate to={RoutePaths.Root} replace />
          )
        }
      />

      {/* Super Admin side menu routes */}
      {superAdminMode && isSuperAdmin ? (
        <Route element={<SuperAdminSideMenu />}>
          <Route path={RoutePaths.MosqueAdmin} element={<AddMosquePage />} />
          <Route path={RoutePaths.Dua} element={<DuaPage />} />
          <Route path={RoutePaths.GlobalPush} element={<GlobalPushPage />} />
          <Route path={RoutePaths.HijriDates} element={<HijriPage />} />
          <Route path={RoutePaths.Users} element={<UsersPage />} />
          <Route
            path={RoutePaths.SuperAdminPrayerTimes}
            element={<SuperAdminPrayerTimesPage />}
          />
          <Route
            path={RoutePaths.NamesOfAllah}
            element={<NamesOfAllahPage />}
          />
          <Route path={RoutePaths.Expressions} element={<ExpressionsPage />} />
        </Route>
      ) : (
        // Logged-in routes with regular layout
        <Route element={<LayoutWithSideMenu />}>
          <Route path={RoutePaths.Root} element={<AdminPage />} />
          <Route path={RoutePaths.Jamat} element={<JamatPage />} />
          <Route path={RoutePaths.PrayerTimes} element={<PrayerTimesPage />} />
          <Route
            path={RoutePaths.SpecialPrayerSettings}
            element={<SpecialPrayerSettings />}
          />
          <Route
            path={RoutePaths.Notification}
            element={<NotificationPage />}
          />
          <Route path={RoutePaths.Event} element={<EventPage />} />
          <Route path={RoutePaths.Blog} element={<BlogPage />} />
          <Route path={RoutePaths.Posters} element={<PostersPage />} />
          <Route path={RoutePaths.Information} element={<InformationPage />} />
          <Route path={RoutePaths.Campaign} element={<CampaignPage />} />
        </Route>
      )}

      {/* Redirect to root for unmatched routes */}
      <Route
        path={RoutePaths.NotFound}
        element={<Navigate to={RoutePaths.Root} />}
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Routes>
        {/* Public route */}
        <Route path={RoutePaths.Login} element={<Login />} />
        <Route
          path={RoutePaths.MosqueRequestForm}
          element={<CreateMosqueRequestPage />}
        />

        {/* Protected routes that require authentication context */}
        <Route path={RoutePaths.NotFound} element={<ProtectedRoutes />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
