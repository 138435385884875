import {
  faBell,
  faCalendar,
  faClock,
  faPeopleGroup,
  faPersonPraying,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "AuthProvider";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { MenuItem, SideMenu } from "stories";
import { ReactComponent as DefaultLogo } from "../../assets/default-logo.svg";
import styles from "./SuperAdminSideMenu.module.scss";

const superAdminPanels = [
  {
    id: "moske-admin",
    label: "Moskèer",
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
  },
  { id: "dua", label: "Dua", icon: <FontAwesomeIcon icon={faPersonPraying} /> },
  {
    id: "hijri-datoer",
    label: "Hijridatoer",
    icon: <FontAwesomeIcon icon={faClock} />,
  },
  {
    id: "brukere",
    label: "Brukere",
    icon: <FontAwesomeIcon icon={faPeopleGroup} />,
  },
  {
    id: "global-push-melding",
    label: "Push meldinger",
    icon: <FontAwesomeIcon icon={faBell} />,
  },
  {
    id: "allahs-navn",
    label: "Allahs navn",
    icon: <FontAwesomeIcon icon={faQuoteLeft} />,
  },
  {
    id: "vanlige-uttrykk",
    label: "Uttrykk",
    icon: <FontAwesomeIcon icon={faQuoteRight} />,
  },
];

const SuperAdminSideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setOrgNumber, setUid, setSuperAdminMode } = useAuth();

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("orgNumber");
    sessionStorage.removeItem("selectedOrgNumber");
    sessionStorage.removeItem("mosqueName");
    sessionStorage.removeItem("mosqueLogo");
    sessionStorage.removeItem("isSuperAdmin");
    sessionStorage.removeItem("prayerTimeTableId");
    sessionStorage.removeItem("isSuperAdminMode");

    setOrgNumber("");
    setUid("");

    navigate("/innlogging");
  };

  return (
    <div className="App">
      <div className="sideMenu">
        <SideMenu>
          <div style={{ textAlign: "center" }}>
            <DefaultLogo
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            />

            <h4
              style={{
                color: "rgba(230, 233, 230, 0.444)",
                fontWeight: "bold",
              }}
            >
              Admin
            </h4>
          </div>

          {superAdminPanels.map((panel) => {
            const isActive = location.pathname === `/${panel.id}`;
            return (
              <Link
                to={`/${panel.id}`}
                key={panel.id}
                className={isActive ? styles.activeLink : ""}
              >
                <MenuItem label={panel.label} icon={panel.icon} />
              </Link>
            );
          })}

          <div style={{ marginTop: "auto", padding: "10px" }}>
            <button
              onClick={() => {
                setSuperAdminMode(false);
                sessionStorage.removeItem("isSuperAdminMode");
                navigate("/velg-moske");
              }}
              className="btn btn-secondary"
              style={{ width: "100%", marginBottom: "15px" }}
            >
              Velg moske
            </button>
            <button
              onClick={handleLogout}
              className="btn btn-primary"
              style={{ width: "100%" }}
            >
              Logg ut
            </button>
          </div>
        </SideMenu>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default SuperAdminSideMenu;
